import React from "react";

const NotFoundPage = () => {
  return (
    <>
      <h1 style={{ marginTop: "80px" }}>404: Not found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </>
  );
};

export default NotFoundPage;
